<template>
	<el-dialog
		:title="title"
		:visible.sync="dialogVisible"
		:width="width"
		:before-close="handleClose"
		:close-on-click-modal="false">
		<slot></slot>
		<span slot="footer" class="dialog-footer">
			<el-button @click="handleClose">取 消</el-button>
			<el-button type="primary" v-if="showConfirm" @click="handleConfirm">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	name: 'EDialog',
	props: {
		width: {
			type: String,
			default: '30%'
		},
		title: {
			type: String,
			default: ''
		},
		showConfirm: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			dialogVisible: false,
		}
	},
	methods: { 
		handleOpen() {
			this.dialogVisible = true;
		},
		handleClose() {
			this.close();
		},
		handleConfirm() {
			this.$emit('confirm');
			// this.close();
		},
		close() {
			this.dialogVisible = false;
			this.$emit('close');
		}
	}
}
</script>
