<template>
	<el-dialog width="346px" :visible.sync="dialogTableVisible" :close-on-click-modal="false" @close="before_close">
		<div class="tc" v-show="type === 1">
			<div v-if="loginType === 1" @click="getTicket">
				<img height="215" width="215" :src="url" alt="">
				<div class="flex_r f_ai_c pt10 pb10 f_jc_c">
					<img width="16" height="16" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAABc0lEQVRYR+1XMUvDUBi8E4cO9k8kBQcnf4G6Cmo76CYk6ayDoI7W0UVBV026OHWw6CpYV3cHoSn9A4JQxcHhpMGKlbbP0Eg6JEOGvMt9l3sv33tHpHzxZ33L1TGB+YGaiPY0sP3kszNMs+VomcSu4ZsewoD7Pcy3ANuVjGYIpbDK+jBcwVNZwrmRB0AYMKod3WxXRwD2ADQk3A8i4BSeQ59nJnLL0cEvTJ/LJBYALAo4aQXc6Qm4ix4Kh60qK6Yi44xbjiokuiIbYcClTEDmwIQ44CmA4FDYaFZZG2eVm961PW1BOAVRD32WIgdmPeU/gNWWz0sTQRLjtqNi7g23jzW+9jWJJMjjckyGALusQlzlSeDDCzZpubomsJIEYVwOATfpC4h2wzSnIK5tSeMn4y+YW9fMex5rqTUi29MVhGJqrdh2lZ2IMgcyB0YFk25iYoxgMjJXDAwmX+kovWjW6++GcPqS62Cze4z6l3Ca9CbzV75Pm3kemGPs/vcAAAAASUVORK5CYII=" alt="">
					<span class="pl10"> 打开微信扫一扫，立即注册或登录</span>
				</div>
				<div class="pt10" v-if="fail" @click="getTicket">二维码已过期，请重新获取</div>
			</div>
			<div v-if="loginType === 2">
				<el-form label-width="auto">
					<el-form-item label="账号">
						<el-input v-model="account.mobile"></el-input>
					</el-form-item>
					<el-form-item label="密码">
						<el-input v-model="account.password"></el-input>
					</el-form-item>
				</el-form>
				<el-button type="primary" style="width: 100%" @click="login">登录</el-button>
			</div>
			<div v-if="loginType === 3">
				<el-form label-width="auto">
					<el-form-item label="手机号">
						<el-input v-model="account.mobile"></el-input>
					</el-form-item>
					<el-form-item label="验证码">
						<el-input v-model="account.code">
							<span slot="append" v-if="!state" @click="sendlogin">获取验证码</span>
							<span slot="append" v-else>{{currentTime}}s 后可重新获取</span>
						</el-input>
					</el-form-item>
				</el-form>
				<el-button type="primary" style="width: 100%" @click="mobilelogin">登录</el-button>
			</div>
			<ul class="flex_r f_jc_a pt30">
				<li class="flex_r f_ai_c cur_p" @click="chose(1)">
					<img v-if="loginType === 1" width="22" height="22" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAANlBMVEUAAAAkbf8gYP8kX/8kYv8hYP8jYP8kYP8jY/8jYf8iYP8iYv8iYP8jYv8jYf8jYf8kYf8jYf/3jQ3OAAAAEXRSTlMAByAjOU1QVVhncHh/qs3S132g6ToAAAB+SURBVBjTdZFJEoAgEAODC8qq/f/PelCk0Jo+9iGQifQw+Rj9pAGXDgA4kut2r7zUvdnMQL7twodFklwFVqlAkQJQnaQEhDcnByBJOgH1BAGnNPPXzPIApesNwCsCeQCIlv6GlDvEeNL4oFXHVSC08msrb5zKOqw1gzXaf+IL4R4VH11hzKoAAAAASUVORK5CYII=" alt="">
					<img v-else width="22" height="22" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAARVBMVEUAAADb///n7/fp8PDp7fbp7PXr7vPr7fTq7vTq7PTr7fXq7vTq7fTn6fLq7fTm6vGfpri2u8q4vsy9w9DEydbR1eDq7fSUexTaAAAAEHRSTlMAByAjOVBYcHh5f83S1dfih3BhHAAAAIdJREFUGNN1kUESgyAQBAcVMaIRg/T/n5qDIZRa28cuapbdkX50PgTf6YKbFgBYJtfsGPkTx2pnLsynHbgxSJJbgT2lAiWlDEQn6QXko748MjBJ2oDUEhLwlnqeml4eoDT9AfAKwHEBCJa+h5QzxBhpfNBax61ArsvvdXnjVNZhrRqs0p4VfwHj4Rw2s3QQRAAAAABJRU5ErkJggg==" alt="">
					<span :class="loginType === 1 && 'cr_p'" class="pl4">扫码登录</span>
				</li>
				<li class="flex_r f_ai_c cur_p" @click="chose(2)">
					<img v-if="loginType === 2"  width="22" height="22" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAA/FBMVEUAAAAgYP8cVf8eWv8cY/8kZP8gYP8nZP8jY/8kYP8kYv8iYv8kYf8kYP8iYf8kYf8jYf8jYf8jYf8kYf8jYv8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8mY/8pZf8vav8xa/8ybP8zbP8zbf84cP85cP9Adv9di/9gjf9hjv9ijv9lkP9rlP9ul/9vmP+Cpf+Lq/+Nrf+XtP+cuP+gu/+ivP+mv/+pwf+qwv+rw/+sxP+uxf+vxf+wx/+xx/+yyP+4zP+8z/+9z/+/0f/B0v/D1P/F1f/G1v/K2f/L2v/O3P/Q3v/k7P/l7P/u8//v8/+0hN/wAAAAIHRSTlMACAkREhwgISRqa3h5erKzytLT19nh4uPk5e/w8vP5+vnMTyUAAAD2SURBVBgZBcHLThsxAADA8drePEiaUAFHpEgc+v9/0k+gp0qFQhISZW3vLjMBiH3fr+ZrK2UEAcLdjx5QzpcJEf3uPgLEdRhHIvnnBgAWqUyisN0CAHkqc7Leg9X9Zrp83GDfvrq0B/lQXv/MLxHsUtdncFffhvK3bkHuuwTEK1wikNMSbJ+7kNWnqZ5gkZbg8Fpik47p8BuWaerg4z9gA3Q30OPxERncUgEzVgAMqYEJbzCCmkrNWOUWrro5bqCW1I4PeP9VA+b0D44t6HY7AMDpc4pmOQGA27mKtDovAHA6DkSMQ0sRUD7PDQGIi9yvXUsdRvANtzNhfRkqexEAAAAASUVORK5CYII=" alt="">
					<img v-else width="22" height="22" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAA6lBMVEUAAADf3//j4//w8PDx8fHt7fbn7/fo8Pfq6vHp7vPq7PPq7vTq7PTq7PXq7PTq7PTq7fTq7fTq7fTq7fTq7fTp7fTq7fTq7fTq7fTq7fTq7fTq7fTq7fTq7fXp7fTq7fSfprijqbuqsMGrscKsssKsssOutMSvtcWwtsWxtsaxt8eyuMezuci1u8q2u8q3vMu3vcu4vcy4vsy5vsy6v827wc+8wc++w9C/xdLDyNXEydXHzNjO0t7O097Q1N/S1uHT1+HT1+LT2OLV2ePf4+vi5e7i5u7k5+/k6O/l6PDm6fDo6/Lp7PPq7fRaJqevAAAAIHRSTlMACAkREhwgISRqa3h5erKzytLT19nh4uPk5e/w8vP5+vnMTyUAAAD1SURBVBgZXcGJVoJQFAXQI6ApoRglFhRq5dCESqNZDmmD6fn/3+ndF2s57I1/ZsGtRGeHbsHEWsYJmAqKBlI5jxu8HLRslVuqWSiZMneUDQD71D4frm8fv6g5gBVSLFqv3z9PzV+K0IJNbXRD5WpEzUaJ2vCeSjKk5sKnmDbqSRwn9caEwkdEcfH2MZ7NxtP3c4oIpxR9pvoUEY4o7kgOBiRjCh8liphkr0cypnBhU3RJzuckuxQ2rJBKe7FaksvVokUltACHystlp610ms9UHADGAXd4BpT8Cbcc56Htedzg5ZAyigFTQdHAmmm7lVqt4tomtD/Tl2HP0ewfzwAAAABJRU5ErkJggg==" alt="">
					<span :class="loginType === 2 && 'cr_p'" class="pl4">密码登录</span>
				</li>
				<li class="flex_r f_ai_c cur_p" @click="chose(3)">
					<img v-if="loginType === 3" width="22" height="22" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAA9lBMVEUAAAAgYP8cVf8eWv8cY/8kZP8gYP8nZP8jY/8kYP8kYv8iYv8kYf8kYP8iYf8kYf8jYf8jYf8jYf8kYf8jYv8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8jYf8xa/80bf83b/85cP85cf88c/89dP8/df9He/9ThP9UhP9aif9ijv9kj/9lkP9lkf9slv9vmP95n/97oP98of+ApP+Bpf+Cpf+Epv+FqP+Hqf+Lq/+LrP+Nrf+Prv+Qr/+RsP+fuv+lv/+qwv+uxf+vxf+xx//I1//O3P/P3P/Q3v/W4f/X4v/b5f/c5v/p7/////9oCh5iAAAAIHRSTlMACAkREhwgISRqa3h5erKzytLT19nh4uPk5e/w8vP5+vnMTyUAAAD+SURBVBgZXcENV8FgGAbg2zZijWllaqu5i0qlsb6lYukDJfr/f6Znr52DrgsLesGuBAfbdkHHUsbymPKKGlI5hyucHJRslWuqWYhMmf+UNQCbVOq96bRXp2IBhk8yGk6+bsnr8XgYkfQNmBQvD6dUTu76FCZKFI+XTLX7FDZcim7IxFWLUZ/CRUDRDdk64/0nGQ0oAuxTPHUY/b6+kYxiigA7FOE72YgpPjoULkoUje9DKkezYwobJhPPIyqjGyZMGD4T8bxNtucxE74BWFQuJrOf8TkVC4C2xYVmkwuOBpHf45rdPJQNhyucHFJa0WPKK2pY0k27UqtVbFOH8gdWazYEVkVsXwAAAABJRU5ErkJggg==" alt="">
					<img v-else width="22" height="22" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAMAAADzapwJAAAA4VBMVEUAAADf3//j4//w8PDx8fHt7fbn7/fo8Pfq6vHp7vPq7PPq7vTq7PTq7PXq7PTq7PTq7fTq7fTq7fTq7fTq7fTp7fTq7fTq7fTq7fTq7fTq7fTq7fTq7fTq7fXp7fTq7fSZoLOhqLqmrL2mrb6orr+qsMGrscGrscKts8O2u8q3vMu4vsy6v868ws/Cx9TDyNXEyNXEydXFytbGy9fHzNjIzNjJztrKztrKz9vO0t7P097S1uDS1uHT1+HW2uTY3OXY3Obd4Ong4+zg5Ozh5O3i5e3i5e7j5u7k5+/l6PDq7fRUNL1mAAAAIHRSTlMACAkREhwgISRqa3h5erKzytLT19nh4uPk5e/w8vP5+vnMTyUAAAD6SURBVBgZXcENN8JgGAbgu22lZrWMFhtLmUryovK1JoTi/v8/yLN3O6dyXciZNbcVnR26NRMbJSdgIagbKFQ8bvEq0Mpt7miXIUpN/tM0AOxTW0/ieLKm5gBWSDIZ9y8fyefBYJyQDC3YFLezT2pfD4rCRoNi+spCqihc+BTTlJmXBRNF4SOimKZcfHB2RSaKIsIpxf2cyfnNNclEUUQ4okhH5EpRjOYUPhoUq/iX2k/vm8KFzczdkNrwiRkbVsiM6qZk2lXMhBbgUHvr9y4G79QcAMYBc8slc54BUT3hjuMqtD2PW7wKCkY9YCGoG9gwbbfV6bRc24T2B9dQXgPaK2U4AAAAAElFTkSuQmCC" alt="">
					<span :class="loginType === 3 && 'cr_p'" class="pl4">验证码登录</span>
				</li>
			</ul>
		</div>

		<div v-show="type === 2">
			<el-form label-width="auto">
				<el-form-item label="手机号">
					<el-input v-model="bind.mobile"></el-input>
				</el-form-item>
				<el-form-item label="密码">
					<el-input v-model="bind.code">
						<span slot="append" v-if="!state" @click="send">获取验证码</span>
						<span slot="append" v-else>{{currentTime}}s 后可重新获取</span>
					</el-input>
				</el-form-item>
			</el-form>
			<el-button type="primary" style="width: 100%" @click="bindAccount">绑定</el-button>
		</div>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			dialogTableVisible: false,
			loginType: 1,
			url: '',
			ticket: '',
			fail: false,
			type: 1,
			bind: {},
			state: false,		//是否开启倒计时
			currentTime: 0,
			totalTime: 60,
			recordingTime: 0, 	//记录时间变量
			check: {},
			loaded: true,
			checkTickets: true,
			account: {},
			setInt: ''
		}
	},
	methods: {
		mobilelogin(){
			let that = this
			this.post('?server=auth.user.login&method=mobile',{
				phone: that.account.mobile,
				code: that.account.code,
				event: 'login'
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调status
				console.log(response.data)
				localStorage.setItem('token', response.data.token)
				that.setCookie('token', response.data.token)
				that.dialogTableVisible = false
				window.location.reload()
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		login(){
			let that = this
			this.post('?server=auth.user.login',{
				phone: that.bind.mobile,
				password: that.bind.password,
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调status
				console.log(response.data)
				localStorage.setItem('token', response.data.token)
				that.setCookie('token', response.data.token)
				that.dialogTableVisible = false
				window.location.reload()
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		chose(type){
			this.loginType = type
			this.checkTickets = false
			if(type == 1){
				this.state = false
				this.currentTime = 0
				this.recordingTime = 0
				this.checkTickets = true
				this.checkTicket()
			}
		},
		before_close(){
			this.loaded = true
			this.state = false
			this.currentTime = 0
			this.recordingTime = 0
			this.checkTickets = false
		},
		getTicket(){
			if(this.loaded){
				this.fail = false
				let that = this
				that.loaded = false
				this.get('?server=auth.we.ticket.get',{}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调status
					console.log(response.data)
					that.url = response.data.url
					that.ticket  = response.data.ticket
					that.checkTicket()
					// that.userInfo.mobile = response.data.mobile
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			}
		},
		sendlogin(){
			let that = this
			this.post('?server=sms.send',{event: 'login',mobile:this.account.mobile}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调status
				console.log(response.data)
				that.$message.success(response.data.msg)
				//把显示时间设为总时间
				that.currentTime = that.totalTime;
				//开始倒计时
				that.state = true;
				//执行倒计时
				that.checkingTime();
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		send(){
			let that = this
			this.post('?server=sms.send',{event:'bind',mobile:this.bind.mobile}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调status
				console.log(response.data)
				that.$message.success(response.data.msg)
				//把显示时间设为总时间
				that.currentTime = that.totalTime;
				//开始倒计时
				that.state = true;
				//执行倒计时
				that.checkingTime();
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		checkingTime(){
			let that = this;
			//判断是否开启
			if(this.state) {
				//判断显示时间是否已到0，判断记录时间是否已到总时间
				if(this.currentTime > 0 && this.recordingTime <= this.totalTime){
					//记录时间增加 1
					this.recordingTime = this.recordingTime + 1;
					//显示时间，用总时间 - 记录时间
					this.currentTime = this.totalTime - this.recordingTime;
					//1秒钟后，再次执行本方法
					if(this.recordingTime == 1){
						this.setInt = setInterval(() => {
							//定时器内，this指向外部，找不到vue的方法，所以，需要用that变量。
							that.checkingTime();
						}, 1000)
					}
				} else {
					//时间已完成，还原相关变量
					this.state = false;		//关闭倒计时
					this.recordingTime = 0;	//记录时间为0
					this.currentTime = this.totalTime;	//显示时间为总时间
					clearInterval(this.setInt)
				}
			} else {
				//倒计时未开启，初始化默认变量
				this.state = false;
				this.recordingTime = 0;
				this.currentTime = this.totalTime;
				clearInterval(this.setInt)
			}
		},
		bindAccount(){
			let that = this
			this.get('?server=auth.we.bind',{
				platform: that.check.platform,
				ticket: that.check.ticket,
				openid: that.check.openid,
				phone: that.bind.mobile,
				verify: that.bind.code,
				event: "bind",
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调status
				console.log(response.data)
				localStorage.setItem('token', response.data.token)
				that.setCookie('token', response.data.token)
				that.dialogTableVisible = false
				window.location.reload()
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		checkTicket(){
			let that = this
			let t = setInterval(function() {
				if(that.checkTickets == false){
					clearInterval(t);
				}
				that.get('?server=auth.we.ticket.get&method=check',{ticket:that.ticket}).
				then(function (response) {
					//这里只会在接口是成功状态返回
					//联网成功的回调status
					switch (response.code) {
						case 1:
							that.$message.success(response.msg);
							clearInterval(t);
							that.dialogTableVisible = false
							// that.$router.push('/dashboard');
							localStorage.setItem('token', response.data.token)
							that.setCookie('token', response.data.token)
							window.location.reload()
							break;
						case 2:
							that.type = 2
							that.check.platform = response.data.platform
							that.check.ticket = response.data.ticket
							that.check.openid = response.data.openid
							clearInterval(t);
							break;
						case 3:
							that.fail = true
							clearInterval(t);
							break;

					}
					// that.userInfo.mobile = response.data.mobile
				}).catch(function (error) {
					//这里只会在接口是失败状态返回，不需要去处理错误提示
					console.log(error);
				});
			},1000)

		},
		//设置cookie
		setCookie(name,data) {
			console.log(name)
			console.log(data)
			document.cookie = name + '=' + data + ';domain=.youhuo.live' + ';path=/';
		},
		cookieSet(c_name,value,expire){
			var date = new Date();
			date.setSeconds(date.getSeconds()+expire);
			document.cookie = c_name+'='+escape(value)+';path=/; expires='+date.toGMTString();
		},
		cookieGet(c_name)
		{
			if(document.cookie.length>0){
				let c_start = document.cookie.indexOf(c_name+'=')
				if(c_start!=-1){
					c_start = c_start+c_name.length+1
					let c_end = document.cookie.indexOf(';',c_start)
					if(c_end == -1) c_end=document.cookie.length
					return unescape(document.cookie.substring(c_start,c_end))
				}
			}
			return ""
		}
	},
	created() {
		this.$nextTick(() => {
			// this.getTicket()
		});
	}
}
</script>