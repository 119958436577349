<template>
	<el-card class="mt20" :shadow="shadow">
		<div class="head flex_r f_ai_c f_jc_b pb15">
			<div class="title flex_r f_ai_c f_jc_b">
				<span class="pr10 pl10">{{title}}</span>
				<slot name="title"></slot>
			</div>
			<div class="operation flex_r f_ai_c f_jc_b cur_p" @click="$router.push(pages)">
				<slot name="operation">{{operation}}</slot>
			</div>
		</div>
		<slot></slot>
	</el-card>
</template>

<script>
export default {
	name: 'ECard',
	props: {
		title: {
			type: String,
			default: ''
		},
		pages: {
			type: String,
			default: ''
		},
		operation: {
			type: String,
			default: ''
		},
		shadow: {
			type: String,
			default: 'always'
		}
	}
}
</script>

<style lang="scss" scoped>
.head {
	height: 30px;
}
.title {
	position: relative;
}
.title::before {
	content: '';
	display: inline-block;
	width: 3px;
	height: 12px;
	background-color: #217fff;
	position: absolute;
	left: 0px;
}
</style>