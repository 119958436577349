export const formConfListItem = [
	{
		label: '主播照片',
		prop: '1',
		type: 'solt'
	},
	{
		label: '主播名称',
		prop: 'username',
		type: 'input'
	},
	{
		label: 'qq',
		prop: 'qq',
		type: 'input'
	},
	{
		label: '手机号',
		prop: 'mobile',
		type: 'input'
	},
	{
		label: '邮箱',
		prop: 'email',
		type: 'input'
	},
	{
		label: '直播平台',
		prop: 'pt',
		type: 'select',
		options: [
			{
				name: '淘宝',
				id: 1
			},
		]
	},
	// {
	// 	label: '抖音ID',
	// 	prop: '4',
	// 	type: 'input',
	// },
	{
		label: '主播标签',
		prop: 'bq',
		type: 'input',
	},
	{
		label: '服务领域',
		prop: 'ly',
		type: 'input',
	},
	{
		label: '自我介绍',
		prop: 'per_memo',
		type: 'input',
	},
	{
		label: '更多介绍',
		prop: 'qd_memo',
		type: 'solt',
	}
]