import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Element, { size: 'mini', zIndex: 3000 });
import './style/reset.css'
import ECard from './components/eCard.vue'
import Empty from './components/empty.vue'
import ECharts from './components/echarts.vue'
import ETable from './components/ETable.vue'
import EDialog from './components/EDialog.vue'
import Editor from './components/editor'
import EForm from './components/eForm.vue'
import LoginDialog from './views/loginDialog.vue'
import anchor from './views/anchor.vue'

Vue.component('ECard', ECard)
Vue.component('Empty', Empty)
Vue.component('ECharts', ECharts)
Vue.component('ETable', ETable)
Vue.component('EDialog', EDialog)
Vue.component('Editor', Editor)
Vue.component('EForm', EForm)
Vue.component('LoginDialog', LoginDialog)
Vue.component('anchor', anchor)
Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default Vue  //输出 vue
