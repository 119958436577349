<template>
    <el-dialog width="846px" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
        <div v-show="status == 3 || status == 2">
            <e-form ref="formRef" :formConfList="formConfListItem">
                <template #1>
                    <el-upload
                            class="avatar-uploader"
                            :action="uploadUrl"
                            :show-file-list="false"
                            :data="data"
                            :on-success="handleAvatarSuccess">
                        <img v-if="avatar" :src="avatar" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </template>
                <template #qd_memo>
                    <editor ref="editorRef" />
                </template>
            </e-form>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
        </div>
        <div v-show="status == 0">
            <div class="tc" style="height: 100%">
                <img class="mt100 mb100" width="130" height="130" src="https://kunheng-web-static.oss-cn-hangzhou.aliyuncs.com/common/hongji/waiting.png" alt="">
                <div class="fs16 cr_ff0 mb100">您的主播申请申请已提交，请等待平台审核</div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
    import {formConfListItem} from '@data/anchorApply'
    import editor from '../components/editor.vue'
    export default {
        name: "anchor",
        components: { editor },
        data(){
            return {
                formConfListItem,
                userInfo: {},
                uploadUrl: '',
                origin_url: '',
                dialogTableVisible: false,
                status: 3,
                loaded: true,
                avatar: '',
                data: {}
            }
        },
        methods: {
            handleAvatarSuccess(response){
                if (response.code === 4003) {
                    this.$message.error('您没有权限操作');

                    return false;
                }
                if(response.code == 0){
                    this.$message.error(response.msg);
                    return
                }
                this.avatar = response.data
                this.$message.success(response.msg);
                this.avatar = response.data.url
                this.origin_url = response.data.origin_url
                this.$refs.formRef.initValue()
            },
            confirm(){
                let that = this
                let formData = this.$refs.formRef.formData
                this.post('?server=live.anchor.apply.post',{
                    username:formData.username,
                    pt:formData.pt,
                    ly:formData.ly,
                    qq:formData.qq,
                    mobile:formData.mobile,
                    email:formData.email,
                    per_memo:formData.per_memo,
                    qd_memo:this.$refs.editorRef.content,
                    avatar:this.origin_url,
                    bq:formData.bq}).
                then(function (response) {
                    //这里只会在接口是成功状态返回
                    //联网成功的回调
                    that.$message.success(response.data.msg);
                    window.location.reload()
                    that.status = 0
                    // that.$router.go(-1)
                }).catch(function (error) {
                    //这里只会在接口是失败状态返回，不需要去处理错误提示
                    console.log(error);
                });
            },
            GetAnchorInfo(){
                if(this.loaded){
                    this.loaded = false
                    let that = this
                    this.get('?server=live.anchor.info.get',{}).
                    then(function (response) {
                        //这里只会在接口是成功状态返回
                        //联网成功的回调status
                        if(response.data){
                            that.userInfo = response.data
                            that.$refs.formRef.formData = response.data
                            that.status = response.data.status
                            that.avatar = response.data.avatar
                            if(that.status == 2){
                                that.$notify({
                                    title: '审核驳回提示',
                                    message: response.data.memo,
                                    duration: 0,
                                    type: 'warning'
                                });
                            }

                            that.origin_url = response.data.origin_url
                            that.$refs.editorRef.content = response.data.qd_memo
                            that.$refs.formRef.formData.username = response.data.username

                            that.$refs.formRef.formData.pt = response.data.pt
                            that.$refs.formRef.formData.ly = response.data.ly
                            that.$refs.formRef.formData.qq = response.data.qq
                            that.$refs.formRef.formData.mobile = response.data.mobile
                            that.$refs.formRef.formData.email = response.data.email
                            that.$refs.formRef.formData.per_memo = response.data.per_memo
                            that.$refs.formRef.formData.bq = response.data.bq
                        }

                        // that.userInfo.mobile = response.data.mobile
                    }).catch(function (error) {
                        //这里只会在接口是失败状态返回，不需要去处理错误提示
                        console.log(error);
                    });
                }

            },
        },
        created() {
            this.$nextTick(() => {
                let token = localStorage.getItem('token')
                this.data = {"auth_token": token};
                this.uploadUrl = this.appConfig.api_request_url+'?server=upload.file'
            });
        }
    }
</script>

<style scoped>

</style>