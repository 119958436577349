<template>
	<div :id="id" ref="echart">
	</div>
</template>

<script>
	const echarts = require('echarts/lib/echarts');
	require('echarts/lib/chart/pie');
	require('echarts/lib/chart/line');
	require('echarts/lib/chart/bar');
	// require('echarts/lib/chart/tree');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/legend');
	import walden from '@data/echartTheme.js';
	export default {
		name: 'ECharts',
		props: {
			id : [String, Number],
			options: {
				type: Object,
			},
			mountedLoading: {
				type: Boolean,
				default: true
			}
		},
		data () {
			return {
				echarts: null,
				loadingInst: null
			};
		},
		methods: {
			loadingHandle() {
				if (this.$refs.echart) {
					this.loadingInst = this.$loading({
						target: this.$refs.echart
					});
				}
			},
			loadingCloseHandle() {
				this.loadingInst && this.loadingInst.close();
			},
			async initEcharts() {
				this.loadingHandle();
				console.log(this.options)
				if(!this.id || JSON.stringify(this.options) === '{}') {
					this.loadingInst && this.loadingInst.close();
					return;
				}
				if(this.echarts === null) {
					echarts.registerTheme('walden', walden)
					this.echarts = echarts.init(document.getElementById(this.id), 'walden');
				}
				if(this.echarts) {
					this.$nextTick(() => {
						this.echarts.setOption(this.options, true);
						this.resize();
					})
				}
				this.loadingInst && this.loadingInst.close();
			},
			resize() {
				this.echarts && this.echarts.resize();
			}
		},
		mounted () {
			this.$nextTick(() => {
				this.mountedLoading && !this.loadingInst && this.loadingHandle();
				this.initEcharts();
			})
		},
	};
</script>