import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
		redirect: '/dashboard',
		children: [
			{
				path: '/dashboard',
				name: 'Dashboard',
				component: () => import('../views/dashboard/index.vue'),
				meta: {
					title: '主页'
				}
			},
			{
				path: '/workbench',
				name: 'Workbench',
				component: () => import('../views/workbench/index.vue'),
				meta: {
					title: '工作台'
				}
			},
			{
				path: '/business',
				name: 'Business',
				component: () => import('../views/business/index.vue'),
				meta: {
					title: '商家中心'
				}
			},
			{
				path: '/goodsWarehouse',
				name: 'GoodsWarehouse',
				component: () => import('../views/goods/index.vue'),
				meta: {
					title: '商品库'
				}
			},
			{
				path: '/goodsApply',
				name: 'GoodsApply',
				component: () => import('../views/goods/goodsApply.vue'),
				meta: {
					title: '商品审核'
				}
			},
			{
				path: '/activity',
				name: 'Activity',
				component: () => import('../views/activity/index.vue'),
				meta: {
					title: '商品排期'
				}
			},
			{
				path: '/autotrophyGoods',
				name: '自营商品',
				component: () => import('../views/autotrophyGoods/index.vue'),
				meta: {
					title: '自营商品'
				}
			},
			{
				path: '/autotrophyGoodsDetail',
				name: '商品编辑',
				component: () => import('../views/autotrophyGoods/goodsDetail.vue'),
				meta: {
					title: '商品编辑'
				}
			},
			{
				path: '/specimen',
				name: '样品管理',
				component: () => import('../views/specimen/index.vue'),
				meta: {
					title: '样品管理'
				}
			},
			{
				path: '/goodsCheck',
				name: '商品审核管理',
				component: () => import('../views/check/index.vue'),
				meta: {
					title: '商品审核管理'
				}
			},
			{
				path: '/activityGoodsCheck',
				name: '商品审核管理',
				component: () => import('../views/check/activityGoodsCheck.vue'),
				meta: {
					title: '商品审核管理'
				}
			},
			{
				path: '/specialShow',
				name: '专场活动审核',
				component: () => import('../views/check/specialShow.vue'),
				meta: {
					title: '专场活动审核'
				}
			},
			{
				path: '/bringGoods',
				name: '带货选品管理',
				component: () => import('../views/check/bringGoods.vue'),
				meta: {
					title: '带货选品管理'
				}
			},
			{
				path: '/liveCount',
				name: '待直播场次',
				component: () => import('../views/check/liveCount.vue'),
				meta: {
					title: '待直播场次'
				}
			},
			{
				path: '/liveDetail',
				name: '直播代办',
				component: () => import('../views/check/liveDetail.vue'),
				meta: {
					title: '直播代办'
				}
			},
			{
				path: '/handCard',
				name: '手卡',
				component: () => import('../views/check/handCard.vue'),
				meta: {
					title: '手卡'
				}
			},
			{
				path: '/listWaitSample',
				name: '未寄样商品',
				component: () => import('../views/check/listWaitSample.vue'),
				meta: {
					title: '未寄样商品'
				}
			},
			{
				path: '/operationRecord',
				name: '操作记录',
				component: () => import('../views/check/operationRecord.vue'),
				meta: {
					title: '操作记录'
				}
			},
			{
				path: '/addActivity',
				name: '添加活动',
				component: () => import('../views/activity/addActivity.vue'),
				meta: {
					title: '添加活动'
				}
			},
			{
				path: '/detailPartner',
				name: '商家数据',
				component: () => import('../views/business/detailPartner.vue'),
				meta: {
					title: '商家数据'
				}
			},
			{
				path: '/commodityDetails',
				name: '数据',
				component: () => import('../views/business/commodityDetails.vue'),
				meta: {
					title: '数据'
				}
			},
			{
				path: '/activeDetail',
				name: '数据',
				component: () => import('../views/activity/activeDetail.vue'),
				meta: {
					title: '数据'
				}
			},
			{
				path: '/contract',
				name: '合同管理',
				component: () => import('../views/finance/contract.vue'),
				meta: {
					title: '合同管理'
				}
			},
			{
				path: '/loans',
				name: '贷款明细',
				component: () => import('../views/finance/loans.vue'),
				meta: {
					title: '贷款明细'
				}
			},
			{
				path: '/liveCountCleaning',
				name: '按场次待办',
				component: () => import('../views/finance/liveCountCleaning.vue'),
				meta: {
					title: '按场次待办'
				}
			},
			{
				path: '/anchor',
				name: '主播首页',
				component: () => import('../views/anchor/index.vue'),
				meta: {
					title: '主播首页'
				}
			},
			{
				path: '/anchorOrganization',
				name: '绑定机构',
				component: () => import('../views/anchor/anchorOrganization.vue'),
				meta: {
					title: '绑定机构'
				}
			},
			{
				path: '/staff',
				name: '员工管理',
				component: () => import('../views/system/staff.vue'),
				meta: {
					title: '员工管理'
				}
			},
			{
				path: '/notification',
				name: '通知管理',
				component: () => import('../views/system/notification.vue'),
				meta: {
					title: '通知管理'
				}
			},
			{
				path: '/jobprocess',
				name: '商品审批设置',
				component: () => import('../views/system/jobprocess.vue'),
				meta: {
					title: '商品审批设置'
				}
			},
			{
				path: '/contractTep',
				name: '合同模板管理',
				component: () => import('../views/system/contractTep.vue'),
				meta: {
					title: '合同模板管理'
				}
			},
			{
				path: '/address',
				name: '收货地址管理',
				component: () => import('../views/system/address.vue'),
				meta: {
					title: '收货地址管理'
				}
			},
			{
				path: '/proceeds',
				name: '收款信息管理',
				component: () => import('../views/system/proceeds.vue'),
				meta: {
					title: '收款信息管理'
				}
			},
			{
				path: '/announcement',
				name: '公告管理',
				component: () => import('../views/system/announcement.vue'),
				meta: {
					title: '公告管理'
				}
			},
			{
				path: '/export',
				name: '导出设置',
				component: () => import('../views/system/export.vue'),
				meta: {
					title: '导出设置'
				}
			},
			{
				path: '/configuration',
				name: '商家报名配置',
				component: () => import('../views/system/configuration.vue'),
				meta: {
					title: '商家报名配置'
				}
			},
			{
				path: '/settingClass',
				name: '商品类目管理',
				component: () => import('../views/system/settingClass.vue'),
				meta: {
					title: '商品类目管理'
				}
			},
			{
				path: '/historyData',
				name: '已播数据管理',
				component: () => import('../views/dataCenter/historyData.vue'),
				meta: {
					title: '已播数据管理'
				}
			},
			{
				path: '/anchorData',
				name: '主播',
				component: () => import('../views/dataCenter/anchorData.vue'),
				meta: {
					title: '主播'
				}
			},
			{
				path: '/goodsData',
				name: '商品',
				component: () => import('../views/dataCenter/goodsData.vue'),
				meta: {
					title: '商品'
				}
			},
			{
				path: '/monitoring',
				name: '我的监控',
				component: () => import('../views/dataCenter/monitoring.vue'),
				meta: {
					title: '我的监控'
				}
			},
			{
				path: '/dataRecord',
				name: '商品数据报表',
				component: () => import('../views/dataCenter/dataRecord.vue'),
				meta: {
					title: '商品数据报表'
				}
			},
			{
				path: '/verification',
				name: '实名认证',
				component: () => import('../views/mine/verification.vue'),
				meta: {
					title: '实名认证'
				}
			},
			{
				path: '/editPassword',
				name: '修改密码',
				component: () => import('../views/mine/editPassword.vue'),
				meta: {
					title: '修改密码'
				}
			},
			{
				path: '/order',
				name: '订单管理',
				component: () => import('../views/mine/order.vue'),
				meta: {
					title: '订单管理'
				}
			},
			{
				path: '/vip',
				name: '会员权益',
				component: () => import('../views/mine/vip.vue'),
				meta: {
					title: '会员权益'
				}
			},
			{
				path: '/basicInfo',
				name: '基本信息',
				component: () => import('../views/mine/basicInfo.vue'),
				meta: {
					title: '基本信息'
				}
			},
			{
				path: '/note',
				name: '短信套餐',
				component: () => import('../views/mine/note.vue'),
				meta: {
					title: '短信套餐'
				}
			},
			{
				path: '/shop',
				name: '店铺管理',
				component: () => import('../views/shop/shop.vue'),
				meta: {
					title: '店铺管理'
				}
			},
		]
	},
	{
		path: '/goodsDetail',
		name: '商品详情',
		component: () => import('../views/goods/goodsDetail.vue'),
	},
	{
		path: '/handCardDetail',
		name: '手卡详情',
		component: () => import('../views/check/handCardDetail.vue'),
	},
	{
		path: '/configurationView',
		name: '商品类目预览',
		component: () => import('../views/system/configurationView.vue'),
	},
	{
		path: '/foundation',
		name: '会员权益',
		component: () => import('../views/dataCenter/foundation.vue')
	},
	{
		path: '/activeDetails',
		name: '会员权益',
		component: () => import('../views/dataCenter/activeDetail.vue')
	},
	{
		path: '/goodsDetailDataCenter',
		name: '会员权益',
		component: () => import('../views/dataCenter/goodsDetail.vue')
	},
	{
		path: '/liveDetails',
		name: '会员权益',
		component: () => import('../views/workbench/liveDetail.vue')
	},
	
	
]

const router = new VueRouter({
  routes
})

export default router
