<template>
  <div id="app">
    <router-view/>
    <login-dialog ref="loginDialog" />
    <anchor ref="anchorDialog" />
  </div>
</template>

<script>
  import Vue from 'vue'
  import appConfig from './common/app.js'
  import {get,post} from '@/request/requestConfig';
  import { Message,MessageBox } from 'element-ui'
  import { Loading } from 'element-ui';
  import axios from "axios";
  // import urlencode from "urlencode";
  export default {
    created() {
      axios.defaults.timeout = 5000; //请求超时5秒
      axios.defaults.baseURL =''; //请求base url
      axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'; //设置post请求是的header信息
//http request 拦截器
      axios.interceptors.request.use(
              config => {
                var token = this.cookieGet('token')
                let pid = localStorage.getItem('pid')
                if(!token){
                  token = localStorage.getItem('token')
                } else {
                  localStorage.setItem('token', token)
                }
                if (token && pid) { // 判断是否存在token，如果存在的话，则每个http header都加上token
                  config.headers = {
                    'TOKEN': token,
                    'PID': pid
                  }
                } else if(token){
                  config.headers = {
                    'TOKEN': token,
                  }
                } else if(pid){
                  config.headers = {
                    'PID': pid
                  }
                }

                return config
              },
              err => {
                return Promise.reject(err)
              }
      )

      var loginTip = 0

      axios.interceptors.response.use(
              response => {
                if (response.data.code === 4003) {

                  // toast.showToast({
                  //     mes: '您没有权限操作！',
                  //     timeout: 1500,
                  //     callback: () => {
                  //         router.go(-1);
                  //     }
                  // });
                  // console.log(this.$refs.anchorDialog)
                  this.$refs.anchorDialog.dialogTableVisible = true
                  this.$refs.anchorDialog.GetAnchorInfo()
                  Message.error('请先完成主播绑定');
                  // router.push('/error');

                }

                if (response.data.code === -1) {
                  if(loginTip == 0){
                    Message.error('您没有登入');
                  }
                  loginTip++
                  // localStorage.removeItem('token')
                  // console.log(window.location.href)
                  // let nowUrl = window.location.href
                  // console.log(router.currentRoute.fullPath)
                  // let authUrl = appConfig.api_auth_url
                  // window.location.href = authUrl+"?return_uri="+urlencode(nowUrl)
                  this.$refs.loginDialog.dialogTableVisible = true
                  this.$refs.loginDialog.checkTickets = true
                  this.$refs.loginDialog.getTicket()
                }
                return response
              },
              err => {
                if (err.code === 'ECONNABORTED' && err.message.indexOf('timeout') !== -1) {
                  // toast.showToast({
                  //     mes: '网络异常，连接超时...',
                  //     timeout: 1500
                  // });
                }
                return Promise.reject(err)
              }
      )
      Vue.prototype.get = get
      Vue.prototype.post = post
      Vue.prototype.appConfig = appConfig
      Vue.prototype.$message = Message
      Vue.prototype.$loadings = Loading
      Vue.prototype.$confirm = MessageBox.confirm
      if(this.GetUrlParam("auth_token")){
        localStorage.setItem('token', this.GetUrlParam("auth_token"))
      }
    },
    methods: {
      cookieGet(c_name)
      {
        if(document.cookie.length>0){
          let c_start = document.cookie.indexOf(c_name+'=')
          if(c_start!=-1){
            c_start = c_start+c_name.length+1
            let c_end = document.cookie.indexOf(';',c_start)
            if(c_end == -1) c_end=document.cookie.length
            return unescape(document.cookie.substring(c_start,c_end))
          }
        }
        return ""
      },
      GetUrlParam(name) {
        var url = window.location.href;

        let params = url.substr(url.lastIndexOf("?") + 1).split("&");
        for (let i = 0; i < params.length; i++) {
          let param = params[i];
          let key = param.split("=")[0];
          let value = param.split("=")[1];
          if (key === name) {
            return value;
          }
        }
      }
    }
  }
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
#nprogress .bar {
  background: #409EFF !important; //自定义颜色
  height: 5px !important;
}

</style>
