import axios from 'axios';
import { showLoading, hideLoading } from '../common/loading';
// import router from '../router';
import appConfig from '../common/app.js'
// import urlencode from "urlencode"
import { Message } from 'element-ui'
// import router from "../router"

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params={}, headers={}){
    let baseUrl = appConfig.api_request_url
    showLoading()//显示加载中
    return new Promise((resolve, reject) => {
        axios.get(baseUrl+url,{
            params: params,
            headers: headers
        })
            .then(response => {
                hideLoading()//关闭加载
                if(response.data.code == 0){
                    Message.error(response.data.msg);
                    reject(response.data.msg)
                    return
                }
                resolve(response.data);
            })
            .catch(err => {
                Message.error('系统超时，请重试');
                hideLoading()//关闭加载
                reject(err)
            })
    })
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}){
    let baseUrl = appConfig.api_request_url
    showLoading()//显示加载中
    return new Promise((resolve, reject) => {
        axios.post(baseUrl+url, data)
            .then(response => {
                hideLoading()//关闭加载
                if(response.data.code == 0){
                    Message.error(response.data.msg);
                    reject(response.data.msg)
                    return
                }
                resolve(response.data);
            }, err => {
                hideLoading()//关闭加载
                Message.error('系统超时，请重试');
                reject(err)
            })
    })
}