<template>
  <el-container>
	<el-header class="header">
		<div class="top flex_r f_ai_c">
			<div class="account flex_r f_ai_c">
				<el-avatar size="large" :src="userInfo.avatar"></el-avatar>
				<span class="pl10 fw_b">
					<el-select style="width: 120px" v-model="pid" @change="styleChange">
						<el-option :label="userInfo.username" :value="1"/>
						<el-option v-for="p in pidLists" :key="p.index" :label="p.username" :value="p.id"/>
					</el-select>
				</span>
			</div>
			<div class="header_con flex_r f_jc_b flex1">
				<div class="left flex_r f_ai_c">
					<i @click="collapse = !collapse" class="fs20 mr20" :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
					<el-popover
							placement="bottom"
							title=""
							width="400"
							trigger="click">
						<div class="tc">
							<el-input disabled v-model="copyUrl"></el-input>
							<el-button class="mt20" @click="copyActive">点击复制</el-button>
						</div>
						<el-button slot="reference"  size="mini" round @click="fz">复制邀请地址</el-button>
					</el-popover>
<!--					<el-button size="mini" round @click="fz($event)">复制邀请地址</el-button>-->
				</div>
				<div class="flex_r f_ai_c pr30">
					<span>欢迎用户</span>
					<span class="cr_fff pl10 pr10">{{ userInfo.mobile }}</span>
					<span>|</span>
					<span class="cr_fff pl20 pr60" @click="logout()" style="cursor: pointer">退出登录</span>
					<el-badge :value="0" class="item">
						<i class="el-icon-s-comment fs30 cr_007"></i>
					</el-badge>
				</div>
			</div>
		</div>
	</el-header>
	<el-container>
		<el-aside :width="collapse ? '64px' : '200px'" style="transition: all 0.5s">
			<div class="menus">
				<el-menu
					ref="menu"
					background-color="#1F222B"
					text-color="#fff"
					active-text-color="#409EFF"
					:router="true"
					:default-active="$route.path"
					:collapse="collapse"
					class="el-menu-vertical-demo">
					<template v-for="menu in menus">
						<el-menu-item :key="menu.path" v-if="menu.type === 'item'" :index="menu.path" :route="{path: menu.path}">
							<i :class="menu.icon"></i>
							<span slot="title">{{menu.name}}</span>
						</el-menu-item>
						<el-submenu v-else :index="menu.path+'sub'" :key="menu.path+'sub'">
							<div slot="title">
								<i :class="menu.icon"></i>
								<span>{{menu.name}}</span>
							</div>
							<el-menu-item v-for="m in menu.children" :key="m.path" :index="m.path" :route="{path: m.path}">
								<!-- <i :class="m.icon"></i> -->
								<span slot="title">{{m.name}}</span>
							</el-menu-item>
						</el-submenu>
					</template>
				</el-menu>
			</div>
		</el-aside>
		<el-main class="main">
			<div class="container">
				<router-view></router-view>
			</div>
		</el-main>
		<e-dialog title="入驻" ref="dialogConfRef">
		</e-dialog>
		<login-dialog ref="loginDialog" />
	</el-container>
</el-container>
</template>

<script>
// import menus from '../data/menus'
import copy from "../common/copy";
export default {
	name: 'Home',
	components: {
	},
	data() {
		return {
			menus : [],
			collapse: true,
			userInfo: {
				avatar: "",
				username: "",
				mobile: ""
			},
			copyUrl: '',
			pid: 1,
			pidLists: []
		}
	},
	methods: {
		Login(){
			console.log(1)
			this.$refs.loginDialog.dialogTableVisible = true
		},
		copyActive(e){
			copy(this.copyUrl, e)
		},
		fz(){
			let that = this
			let loading = this.$loadings.service({
				'text': "请求中",
				background: 'rgba(28,28,28,0.7)',
			})
			that.get('?server=live.invite.url.handle',{}).
			then(function (res) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.copyUrl = res.data.origin
				// copy(res.data.url, e)
				loading.close()

			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
				loading.close()
			});
		},
		logout(){
			this.get('?server=auth.user.logout.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				localStorage.removeItem('token')
				window.location.reload()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetUserInfo(){
			let that = this
			this.get('?server=auth.user.info.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				// that.userInfo.avatar = response.data.avatar
				// that.userInfo.username = response.data.username
				that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetAnchorInfo(){
			let that = this
			this.get('?server=live.anchor.info.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.userInfo.avatar = response.data.avatar
				that.userInfo.username = response.data.username
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetMenu(){
			let that = this
			this.get('?server=auth.menu.lists.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.menus = response.data
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetPidLists(){
			let that = this
			this.get('?server=auth.user.bind.lists.get',{}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				console.log(response)
				that.pidLists = response.data
				// that.userInfo.mobile = response.data.mobile
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		styleChange(){
			localStorage.setItem('pid', this.pid)
			window.location.href = "/"
		},
	},
	created() {
		this.$nextTick(() => {
			this.GetMenu()
			this.GetUserInfo()
			this.GetAnchorInfo()
			this.GetPidLists()
		});
	}
}
</script>
<style>
	.el-link {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 120px;
		min-width: 80px;
	}
	.el-link--inner {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 120px;
		min-width: 80px;
	}
	.html-content {
		overflow: hidden;
		text-overflow: ellipsis;
		/* white-space: nowrap; */
		/* width: 107px; */
		/*min-width: 80px;*/
		height: 120px;
		line-height: 120px;
	}
</style>
<style lang="scss" scoped>
.header {
	background: #1f222b;
	color: #fff
}
.top {
	height: 60px;
}
.account {
	width: 200px;
}
.menus {
	height: calc(100vh - 60px);
	overflow-x: hidden;
	overflow-y: auto;
	background: #1F222B;
}
.el-menu {
	border-right: none;
}
.main {
	background-color: #f4f3f9;
	padding: 15px;
	height: calc(100vh - 60px);
}
.container {
	background-color: #fff;
	padding: 20px;
	box-sizing: border-box;
	border-radius: 10px;
	min-height: 100%;
}
.bg .container {
	background-color: #f4f3f9;
}
</style>
