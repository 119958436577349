<template>
	<div class="flex_c f_jc_c f_ai_c pt10">
		<img width="336" height="104" src="../assets/images/common/empty3.png" alt="" srcset="">
		<span class="pt20 fs12">{{tip}}</span>
	</div>
</template>

<script>
export default {
	name: 'Empty',
	props: {
		tip: {
			type: String,
			default: '暂无数据'
		}
	}
}
</script>