<template>
    <div ref="tbArea" class="tb">
        <div v-if="showOperation" class="operation">
			<slot name="operationArea">  
				<el-form :model="condition" label-width="auto">
					<el-row :gutter="10" class="flex_r flex_wrap">
						<el-col class="mr10" v-for="(item, index) in searchParamsConf" :key="index" :span="item.type === 'daterange' ? 7 : 5">
							<el-form-item :prop="item.prop" :label="item.label">
								<el-input v-if="item.type === 'input'" v-model="condition[item.prop]" 
								:placeholder="'请输入' + item.label"  clearable />
								<el-select v-else-if="item.type === 'select'" clearable v-model="condition[item.prop]">
									<el-option v-for="jtem in item.rotate ? optionRotateList[item.prop] : item.options" :key="item.rotate ? jtem[item.optionLabel] : jtem.value" :label="item.rotate ? jtem[item.optionLabel] : jtem.label" :value="item.rotate ? jtem[item.optionValue] : jtem.value" 
									:placeholder="'请输入' + item.label"/>
								</el-select>
								<el-date-picker
									v-else-if="item.type === 'datetime'"
									:picker-options="pickerBeginDateBefore"
									:key="index"
									v-model="condition[item.prop]"
									type="datetime"
									value-format="yyyy-MM-dd HH:mm:ss"
									:placeholder="'请选择' + item.label"
								/>
								<el-date-picker
									style="width: 140px"
									v-else-if="item.type === 'date'"
									v-model="condition[item.prop]"
									type="date"
									value-format="yyyy-MM-dd"
									placeholder="选择日期">
								</el-date-picker>
								<el-date-picker
									style="width:300px"
									v-else-if="item.type === 'daterange'"
									v-model="condition[item.prop]"
									type="daterange"
									range-separator="至"
									start-placeholder="开始日期"
									end-placeholder="结束日期">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<slot name="expandSearchParams" :conditionData="condition"></slot>
						<el-col :span="1" class="mr10">
							<el-button @click="resetSearch()">重置</el-button>
						</el-col>
						<el-col :span="1" class="mr10">
							<el-button @click="searchParamsChange" type="primary">搜索</el-button>
						</el-col>
						<slot name="customOperation"></slot>
					</el-row>
				</el-form>
            </slot>
        </div>
        <el-table border v-loading="loading" :data="tbData" ref="table" row-key="id" @selection-change="selectChange" header-align="center" :tree-props="treeProps" :default-expand-all="defaultExpandAll">
            <el-table-column v-if="showSelectionCol" type="selection" width="50" fixed="left"></el-table-column>
            <el-table-column v-if="expand" type="expand" align="center">
                <template slot-scope="props">
                    <slot name="expand" :rowData="props.row"></slot>
                </template>
            </el-table-column>
            <el-table-column
				show-overflow-tooltip
                v-for="col in tableCols"
                :key="col.prop"
                :prop="col.prop"
                :label="col.label"
                :width="col.width"
                :formatter="col.formatter"
                :fixed="col.fixed"
            >
                <template v-if="$scopedSlots[col.prop]" #default="scope">
                    <slot :name="col.prop" :rowData="scope.row"></slot>
                </template>
            </el-table-column>
        </el-table>
		<slot name="tableBottom"></slot>
        <el-pagination
			v-if="showPagination"
            class="pagination noprint"
            background
            layout="total, prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="handlePageChange"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    name: 'ETable',
    components: {
    },
    props: {
        showOperation: {
            type: Boolean,
            default: false
        },
        showSelectionCol: {
            type: Boolean,
            default: false
        },
        initDataimmediately: {
            type: Boolean,
            default: true
        },
        expand: {
            type: Boolean,
            default: false
        },
        dataOrigin: {
            type: Object,
            default: () => ({
                data: []
            })
        },
        tableCols: {
            type: Array,
            default: () => []
        },
        customSearchList: {
            type: Array,
            default: () => []
        },
		showPagination: {
			type: Boolean,
            default: true
		},
		treeProps: {
			type: Object,
			default: () => ({children: 'children', hasChildren: 'hasChildren'})
		},
		defaultExpandAll: {
			type: Boolean,
			default: true
		}
    },
    data() {
        return {
            condition: {},
            tbData: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
			filterTbDataParams: {},
			optionRotateList: {},
			loading: false
        };
    },
    created() {
        this.$nextTick(() => {
            this.initDataimmediately && this.initData();
        });
    },
    computed: {
        searchParamsConf() {
            let list = this.tableCols.filter((el) => el.search).concat(this.customSearchList);
            return list;
        }
    },
    methods: {
		selectionData(){
			return this.$refs.table.selection
		},
		clearSelectionData(){
			this.$refs.table.selection = []
		},
		selectAll(data){
			this.$emit('select-all', data);
		},
		selectChange(data) {
			this.$emit('select-change', data);
		},
		resetSearch(){
			this.condition = {}
			this.currentPage = 1;
			this.$emit('resetSearch', {
				condition: this.condition,
				currentPage: this.currentPage,
				pageSize: this.pageSize
			})
		},
        searchParamsChange(val) {
            this.$set(this, 'filterTbDataParams', val);
            this.currentPage = 1;
			this.$emit('searchParamsChange', {
				condition: this.condition,
				currentPage: this.currentPage,
				pageSize: this.pageSize
			})
        },
        initData() {
            if (this.dataOrigin.data) {
                this.tbData = this.dataOrigin.data;
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
            this.queryTableData();
        },
		queryTableData() {
			this.$emit('query-Table-data', {
				condition: this.condition,
				currentPage: this.currentPage,
				pageSize: this.pageSize
			})
		}
    }
};
</script>

<style>
	.el-tooltip__popper{
		max-width:20%;
	}
	.el-tooltip__popper,.el-tooltip__popper.is-dark{
		background:rgb(48, 65, 86) !important;
		color: #fff !important;
		line-height: 24px;
	}
</style>
<style lang="scss" >
.tb {
    background: #fff;
    padding: 15px;
    .operation {
        margin-bottom: 10px;
        overflow: hidden;
        .el-form{
            position: relative;
            z-index: 0;
        }
        .operationArea {
            position: relative;
            z-index: 1;
            float: left;
        }
        .el-input--suffix {
            .el-input__inner{
                padding-right: 0px; 
            } 
        }
    }
    .pagination {
        text-align: right;
    }
    // .el-form-item__label{
    //     color: rgb(22, 22, 22);
    // }
}
</style>
