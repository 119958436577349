<template>
	<div class="form">
		<el-form :model="formData" label-width="auto" :disabled="disabled" :handleAvatarSuccess="handleAvatarSuccess" :beforeAvatarUpload="beforeAvatarUpload">
			<template v-for="item in formConfList">
				<div class="fs20 pt20 pb20 fw_b" v-if="item.type === 'title'" :key="item.prop">{{item.label}}</div>
				<el-form-item v-else :label="item.label" :prop="item.prop" :key="item.prop">
					<div v-if="item.type === 'upload'">
						<el-upload
								class="avatar-uploader"
								:action="item.action"
								:accept="item.accept"
								:show-file-list="false"
								:on-success="handleAvatarSuccess"
								:data="data"
								:headers="headers"
								:before-upload="beforeAvatarUpload">
							<img v-if="imageUrl" :src="imageUrl" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
					</div>

					<el-input :disabled="item.disabled" v-if="item.type === 'input'" v-model="formData[item.prop]" :placeholder="'请输入'+item.label">
						<template slot="append" v-if="item.append">{{item.append}}</template>
					</el-input>
					<el-input :disabled="item.disabled" v-if="item.type === 'textarea'" type="textarea" v-model="formData[item.prop]" :rows="item.rows" :placeholder="'请输入'+item.label"></el-input>
					<el-select @change="selectChange($event,item)" :disabled="item.disabled" v-if="item.type === 'select'" v-model="formData[item.prop]" :placeholder="'请选择'+item.label">
						<el-option :label="option.name" :value="option.id" v-for="option in item.options" :key="option.index"></el-option>
					</el-select>
					<div v-if="item.type === 'color'">
						<el-color-picker v-model="formData[item.prop]"></el-color-picker>
					</div>
					<el-radio-group v-if="item.type === 'radio'" :disabled="item.disabled" v-model="formData[item.prop]">
						<el-radio :label="item.value" v-for="item in item.options" :key="item.value">{{item.label}}</el-radio>
					</el-radio-group>
					<el-checkbox-group v-if="item.type === 'checkboxGroup'" v-model="formData[item.prop]">
						<el-checkbox :label="item.value" v-for="item in item.options" :key="item.value">{{item.label}}</el-checkbox>
					</el-checkbox-group>
					<el-date-picker
						:disabled="item.disabled"
						class="w_200"
						v-else-if="item.type === 'date'"
						v-model="formData[item.prop]"
						type="date"
						value-format="yyyy-MM-dd"
						placeholder="选择日期">
					</el-date-picker>
					<el-date-picker
						:disabled="item.disabled"
						style="width:300px"
						v-else-if="item.type === 'daterange'"
						v-model="formData[item.prop]"
						type="daterange"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期">
					</el-date-picker>
					<span v-if="item.type === 'span'">{{formData[item.prop]}}</span>
					<div v-if="item.type === 'solt'">
						<slot :name="item.prop" :propValue="formData[item.prop]" :data="formData" :item="item"></slot>
					</div>
				</el-form-item>
			</template>
		</el-form>
	</div>
</template>

<script>
export default {
	name: 'EForm',
	props: {
		formConfList: {
			type: Array,
			default: () => ([])
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			formData: {},
			data: {},
			headers: {},
			imageUrl: ''
		}
	},
	created() {
		this.initValue()
	},
	methods: {
		selectChange(e,item){
			this.$emit('selectChange', {value:e,item:item});
		},
		handleAvatarSuccess(file){
			this.$emit('handleAvatarSuccess', file);
		},
		beforeAvatarUpload(file){
			this.$emit('beforeAvatarUpload', file);
		},
		initValue() {
			this.formConfList.forEach(el => {
				if(el.type === 'checkboxGroup') {
					this.$set(this.formData, el.prop, [])
				}
			})
		}
	}
}
</script>

<style lang="scss">
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}
	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}
	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}
	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>