// import { Loading } from 'element-ui';
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

let loadingCount = 0;
// let loading;

const startLoading = () => {
    NProgress.start()
    // loading = Loading.service({
    //     lock: true,
    //     text: '拼命加载中...',//可以自定义文字
    //     spinner:'el-icon-loading',//自定义加载图标类名
    //     background: 'rgba(28,28,28,0.7)',//遮罩层背景色
    //     target: document.querySelector(".container"),
    // });
};

const endLoading = () => {
    NProgress.done()
    // loading.close();
};

export const showLoading = () => {
    if (loadingCount === 0) {
        startLoading();
    }
    loadingCount += 1;
};

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
        endLoading();
    }
};